import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { DEFAULT_OL_NAMES, IOlNamesConfig } from '../models/custom-names.model';

@Injectable()
export class OlNamesService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {}

  async getOlNames(): Promise<IOlNamesConfig> {
    const defaultConfig = {
      variable_wage_1: DEFAULT_OL_NAMES.VARIABLE_WAGE_1,
      variable_wage_2: DEFAULT_OL_NAMES.VARIABLE_WAGE_2,
    };

    try {
      const settings = await this.firestore
        .doc(`empresaMae/${this.authService.dominioId}`)
        .get()
        .toPromise();
      if (!settings.exists) {
        return defaultConfig;
      }

      return settings && settings.data()['ol_names']
        ? settings.data()['ol_names']
        : defaultConfig;
    } catch (err) {
      console.log('dependent config err ---', err);
      return defaultConfig;
    }
  }
}
